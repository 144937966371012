<template>
  <iframe v-if="iframeUrl" :src="iframeUrl" class="iframe" />
</template>

<script>
import { Settings } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { SettingKey } from '@/constants/setting-key'
import { Browser } from '@capacitor/browser'
import {
  isMobile
} from '@seliaco/red-panda/src/components/session/methods/is-mobile'

export default {
  name: 'Office',
  data () {
    return {
      iframeUrl: null
    }
  },
  mounted () {
    this.getContent()
  },
  methods: {
    getContent () {
      Settings.get(SettingKey.SELIA_SPECIALIST_INSIGHTS_URL)
        .then((response) => {
          const url = response.parse_value.replaceAll('{SPECIALIST_ID}', this.specialist.id)

          if (isMobile) {
            this.openBrowser(url)
          } else {
            this.iframeUrl = url
          }
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    async openBrowser (url) {
      await Browser.open({
        url
      })
    }
  },
  computed: {
    ...mapGetters({
      specialist: 'auth/specialist'
    })
  }
}
</script>

<style lang="sass" scoped>
.iframe
  width: 100%
  height: 100vh
</style>
