export const SettingKey = {
  LANGUAGES: 'LANGUAGES',
  FAQ_GENERIC_PACKAGES: 'FAQ_GENERIC_PACKAGES',
  SELIA_USER_CONFIG: 'SELIA_USER_CONFIG',
  SELIA_VIDEO: 'SELIA_VIDEO',
  FAQ_GENERIC_PACKAGE_DETAIL: 'FAQ_GENERIC_PACKAGE_DETAIL',
  FAQ_QUESTIONNAIRE: 'FAQ_QUESTIONNAIRE',
  SELIA_MEDITATIONS: 'SELIA_MEDITATIONS',
  SELIA_COUPON: 'SELIA_COUPON',
  COUNTRIES: 'COUNTRIES',
  SHOW_METRICS_VALIDATION: 'SHOW_METRICS_VALIDATION',
  FAQ_APPOINTMENT_SCHEDULE: 'FAQ_APPOINTMENT_SCHEDULE',
  CURRENCIES: 'CURRENCIES',
  SELIA_NOTIFICATION: 'SELIA_NOTIFICATION',
  SELIA_SPECIALIST_CONFIG: 'SELIA_SPECIALIST_CONFIG',
  SELIA_CONFERENCES: 'SELIA_CONFERENCES',
  OPEN_COUPON_SECTION: 'OPEN_COUPON_SECTION',
  THERAPY_REASON: 'THERAPY_REASON',
  SELIA_SPECIALIST_INSIGHTS_URL: 'SELIA_SPECIALIST_INSIGHTS_URL',
  CHAT_VERSION: 'CHAT_VERSION'
}
